.wrapper-articles-3 {
  a {
    color: #333333;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 300;
    display: unset;
    cursor: pointer;
  }
}

#w-node-_6390e799-996c-456a-04a4-049fb7e276fb-b7e276f1 {
  .container-form {
    a {
      color: #333333 !important;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 300;
      display: unset;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
.richtext-image.regul-links a {
    display: inline !important;
}
.wrapper-articles,
.wrapper-articles-2,
.wrapper-articles-3 {
  overflow: visible;

  .wrapper-table {
    padding-top: 80px;
    max-width: 300px;
    top: 0;
    align-self: start;

    @media screen and (max-width: 767px) {
      max-width: 100%;
      width: 90vw;
      margin: 0 auto;
      position: sticky !important;
      z-index: 3;
      display: flex;
      align-items: center;
      pointer-events: none;
      padding-top: 80vh;
      margin-top: -80vh;
      top: 75vh;

      @media screen and (max-width: 380px) {
        padding-top: 70vh;
      }

      .tag-table {
        display: none;
        padding: 10px 20px;
        width: 400px !important;
        justify-content: flex-start !important;

        .label-table {
          max-width: 94%;
        }
      }

      .tag-table.active {
        position: relative;
        pointer-events: auto !important;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: black !important;
        color: white !important;

        @media screen and (max-width: 767px) {
          &:after {
            content: url(./ui/blog/arrows.svg);
            position: absolute;
            right: 20px;
            margin-top: 5px;
          }
        }
      }
    }

    .tag-table:focus {
      color: rgba(0, 0, 0, 0.5);
      background-color: transparent;
    }
  }

  .wrapper-table-menu {
    opacity: 0;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    pointer-events: none;
    height: 100vh;
    margin-top: -100vh;
    transform: translateY(10px);
    transition:
      0.2s all ease-in,
      transform 0.2s ease-in;
    align-items: center;

    .active {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: black !important;
      color: white !important;
    }

    @media screen and (max-width: 767px) {
      .wrapper-table-menu-container {
        background-color: black;
        border-radius: 20px;
        width: 90vw;
        padding: 15px;
        position: absolute;
        bottom: 10vh;

        a {
          color: dimgrey;
        }
      }

      .wrapper-table-menu-item {
        color: black;
        position: relative;
        padding: 6px 0;

        &:not(:last-child) {
          border-bottom: 1px black solid;
        }
      }
    }
  }

  .wrapper-table-menu.show {
    @media screen and (max-width: 767px) {
      z-index: 5;
      opacity: 1;
      transform: translateY(0);
      transition:
        0.2s all ease-in,
        transform 0.2s ease-in;
      pointer-events: all;
    }
  }

  .wrapper-table.hide {
    @media screen and (max-width: 767px) {
      opacity: 0;
      transform: translateY(0);
      transition:
        0.2s all ease-in,
        transform 0.2s ease-in;
    }
  }

  .container-right-article,
  .article-container-760 {
    .article-chapo {
      margin-bottom: 50px;
    }

    .article-right-content {
      grid-row-gap: 0;

      img {
        width: auto !important;
        height: auto !important;
      }

      a {
        display: inline;
        font-size: 20px;
        color: #333;
        text-decoration: underline;
      }
    }

    .w-richtext figure {
      max-width: 100%;
      margin: 50px auto;
    }

    h2 {
      padding: 30px 0;
      font-size: 40px;
      font-weight: 450;
      line-height: 40px;
    }

    h1 {
      margin: 40px 0;
    }

    h3 {
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 450;
      line-height: 30px;
    }

    p {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
    }

    strong {
      font-weight: 450;
    }

    ol.list-container {
      list-style: none;
      padding: 0;
      margin: 50px 0;

      .list {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        border-top: 1px #cccccc solid;

        .list-number {
          width: 30px;
          height: 30px;
          background-color: #000000;
          color: #ffffff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .list-content {
          width: 88%;

          .list-title {
            font-size: 20px;
            line-height: 25px;
            margin-top: 2px;
          }

          em {
            font-style: normal;
            font-weight: 450;
          }

          p {
            margin-bottom: 20px;
          }
        }
      }
    }

    .check-list-container {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      line-height: 30px;

      .check-list-item {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        margin: 25px 0;

        .check-list-icon {
          &:before {
            content: url(./ui/blog/check.svg);
            display: inline-block;
            width: 30px;
            height: 30px;
          }
        }

        .check-list-content {
          margin-top: 3px;
          width: 90%;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          padding-left: 0;
        }
      }
    }

    blockquote {
      display: block;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 10px;
      border-radius: 20px;
      font-size: 20px;

      &::before {
        content: url("./assets/Quote.svg");
        width: 100%;
        height: 30px;
        display: inline-block;
      }
    }
  }

  a {
    transition: 0.2s text-decoration-color ease-in-out;

    &:hover {
      text-decoration-color: transparent;
      transition: 0.2s text-decoration-color ease-in-out;
    }
  }

  .article-right-content:not(.e-book-align) {
    h2 {
      padding: 70px 0 0 0 !important;
      transform: translateY(-55px);
    }
  }
}

.wrapper-articles,
.wrapper-articles-2,
.wrapper-articles-3 {
  blockquote {
    border: 1px;
    border-radius: 20px;
    padding: 20px 20px 30px 20px !important;
    font-size: 20px;
    display: block;
    background: #ccccfe;
    margin-bottom: 30px;
    line-height: 30px;
  }

  p {
    margin-bottom: 32px !important;
    line-height: 30px !important;
  }

  h2 {
    font-size: 46px !important;
    line-height: 46px !important;
  }
  a {
    img {
      transition: all 0.3s ease-in-out;
    }
  }
  a {
    img:hover {
      transition: all 0.3s ease-in-out;
      transform: scale(0.98);
      filter: drop-shadow(0 4px 16px #00000026);
    }
  }
}

.bloc-corail {
  background: #ff9999;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding: 20px !important;
  margin-bottom: 32px !important;
}
.bloc-corail p {
  margin-bottom: 0 !important;
}
.bloc-corail p strong,
.bloc-corail p b {
  font-weight: 500;
}
.bloc-mauve {
  background: #ccccfe;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding: 20px !important;
  margin-bottom: 32px !important;
}
.bloc-mauve p {
  margin-bottom: 0 !important;
}
.bloc-mauve p strong,
.bloc-mauve p b {
  font-weight: 500;
}
.bloc-vert {
  background: #99ddbb;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding: 20px !important;
  margin-bottom: 32px !important;
}
.bloc-vert p {
  margin-bottom: 0 !important;
}
.bloc-vert p strong,
.bloc-vert p b {
  font-weight: 500;
}
.bloc-turquoise {
  background: #99e5e5;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding: 20px !important;
  margin-bottom: 32px !important;
}
.bloc-turquoise p {
  margin-bottom: 0 !important;
}
.bloc-turquoise p strong,
.bloc-turquoise p b {
  font-weight: 500;
}
.bloc-jaune {
  background: #ffdd99;
  border: 1px;
  border-radius: 20px;
  font-size: 20px;
  line-height: 30px;
  display: block;
  padding: 20px !important;
  margin-bottom: 32px !important;
}
.bloc-jaune p {
  margin-bottom: 0 !important;
}
.bloc-jaune p strong,
.bloc-jaune p b {
  font-weight: 500;
}

// /* Pas d'auteur sur cet article */

// .wrapper-authors-3.nobg.border:first-child {
//   display: none;
// }

/* Correction des titres h4 */
.wrapper-articles-2 .article-right-content:not(.e-book-align) h4, .wrapper-articles .article-right-content:not(.e-book-align) h4 {
  margin: 10px 0 30px 0;
  font-weight: 500;
  font-family: Libre Baskerville,sans-serif;
}

.wrapper-articles-2 .article-right-content:not(.e-book-align) h3, .wrapper-articles .article-right-content:not(.e-book-align) h3 {
  margin: 10px 0 30px 0;
  font-weight: 500;
  font-family: Libre Baskerville,sans-serif;
}

.wrapper-articles-2 .article-right-content:not(.e-book-align) h2, .wrapper-articles .article-right-content:not(.e-book-align) h2 {
  font-size: 36px !important;
  transform: none;
  padding: 0 !important;
  margin: 50px 0 28px 0;
  font-family: Libre Baskerville,sans-serif;
}

.wrapper-articles-2 .article-right-content:not(.e-book-align) h5, .wrapper-articles .article-right-content:not(.e-book-align) h5 {
  margin: 10px 0 30px 0;
  font-weight: 500;
  font-family: Libre Baskerville,sans-serif;
}



/* Table */
table {
  border-collapse: separate;
  margin: 0 auto;
  padding: 0px;
  table-layout: fixed;
  min-width: 100%;
}
table th {
  text-align: center;
  padding: 8px;
  border: 1px solid #000000;
  background: #000000;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}
table th:first-child {
  border-top-left-radius: 25px;
}
table th:last-child {
  border-top-right-radius: 25px;
}
table td {
  padding: 8px;
  border: 1px solid #000000;
  transition: 0.1s;
}
table td:hover {
  background: #ffdd99;
  transition: 0.1s;
}
table tr {
  background-color: #f5f5f5;
  color: #000000;
  text-align: left;
}
table .mobile-head {
  display: none;
}
table tr:last-child td:first-child {
  border-bottom-left-radius: 25px;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 25px;
}
@media screen and (max-width: 600px) {
  table {
    border: 1px solid #000000;
    border-collapse: collapse;
    margin: 0 auto;
    padding: 0px;
    table-layout: fixed;
    min-width: 100%;
  }
  table td {
    padding: 8px;
    border: 1px solid #e6e5e5;
    display: block;
    text-align: right;
    width: 100%\9;
    float: left\9;
  }
  table tr {
    background-color: #dddddd;
    color: #000000;
    text-align: right;
    margin: 8px;
  }
  table tr:first-child {
    display: none;
  }
  table tr {
    display: block;
  }
  table td:not(:first-child) {
    border-top: 0px;
  }
  table .mobile-head {
    font-weight: bold;
    color: #000000;
    float: left;
    text-align: left;
    display: block;
  }
}

/* Images */
.wrapper-articles-2 .container-right-article .w-richtext figure, .wrapper-articles .container-right-article .w-richtext figure {
  margin: 0 auto 25px !important;
}

/* Pour permettre aux utilisateurs de copier-coller le code */
.w-code-block {
  -webkit-user-select: text !important;
  -moz-user-select: -moz-text !important;
  -ms-user-select: text !important;
  user-select: text !important;
  border-radius: 25px;
}
/* Problème d'affichage code-block sur mobile */
@media screen and (max-width: 479px) {
  .w-code-block {
    max-width: 350px !important;
    margin: 0 auto;
  }
  body {
    overflow-wrap: anywhere;
  }
}

/* Sources */
.wrapper-articles-2 .article-right-content .sources h2, .wrapper-articles .article-right-content .sources h2 {
  font-size: 18px !important;
}
.sources p {
  font-size: 14px !important;
  line-height: 18px !important;
  margin-bottom: 10px !important;
}
